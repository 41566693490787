'use client';

import { SGSLogo } from '@/assets';
import { FC } from 'react';
import { HeaderNetworkSelector } from './network-selector';
import { useChainId } from 'wagmi';
import { UserPortfolio } from '../user';

export const Header: FC = () => {
  const chainId = useChainId();
  return (
    <div className="px-4 md:px-6 sticky flex items-center flex-grow gap-4 top-0 z-50 min-h-[56px] max-h-[56px] h-[56px] bg-sgsNav-500 dark:bg-slate-900 border-b border-white/15">
      <div className="flex space-x-1 w-1/2">
        <SGSLogo />
      </div>
      <div className="flex items-center justify-end flex-grow gap-4 text-white w-1/2">
        <HeaderNetworkSelector selectedNetwork={chainId} />
        <UserPortfolio />
      </div>
    </div>
  );
};
